
export default {
  props: {
    ladas: { type: Array, required: true },
  },
  methods: {
    seleccionarLada(lada) {
      this.$emit("seleccionar-lada", lada);
    },
  },
};
