
export default {
  name: "EtiquetaChip",
  props: {
    etiqueta: { type: Object, required: true },
    mostrarIconoEliminar: { type: Boolean, default: true },
  },
  methods: {
    eliminarEtiqueta(etiqueta) {
      this.$emit("eliminar-etiqueta", etiqueta);
    },
  },
};
