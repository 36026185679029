
import LeadService from "@/Services/LeadService";
import AgenteSeccion from "./AgenteSeccion.vue";
export default {
  name: "AgenteCard",
  props: {
    actualizarDatos: {
      type: Boolean,
      default: false,
    },
    usuariosAsignados: {
      type: Array,
      default: () => [],
    },
    lead: {
      type: Object,
      default: () => {},
    },
    mostrarListaAgentes: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    AgenteSeccion,
  },
  data() {
    return {
      agentePrincipal: {},
      agenteSecundario: {},
      usuarios: [],
      excluirUsuarioIds: [],
      esPrimario: false,
    };
  },
  watch: {
    async actualizarDatos() {
      await this.cargaInicial();
    },
  },
  methods: {
    async cargaInicial() {
      await this.buscarUsuariosAsignados();
      await this.obtenerUsuariosIdsPorExcluir();
      await this.obtenerUsuarios(false);
    },
    async obtenerUsuarios(loader = true) {
      if (loader) this.loader(true);
      let datos = {
        ordenar: "nombre_asc",
        status: [200],
        excluirUsuarioIds: this.excluirUsuarioIds,
      };
      await LeadService.listarUsuariosBasico(datos)
        .then((resp) => {
          this.usuarios = resp;
          if (loader) this.loader(false);
        })
        .catch((error) => {
          this.loader(false);
          this.mostrarAlerta(error, "error");
        });
    },
    async buscarUsuariosAsignados() {
      let usuarioPrincipal = this.usuariosAsignados.find(
        (ua) => ua.usuario_primario == true
      );
      if (!_.isEmpty(usuarioPrincipal)) {
        this.agentePrincipal = usuarioPrincipal;
      }
      let usuarioSecundario = this.usuariosAsignados.find(
        (ua) => ua.usuario_primario == false
      );
      if (!_.isEmpty(usuarioSecundario)) {
        this.agenteSecundario = usuarioSecundario;
      }
    },
    async obtenerUsuariosIdsPorExcluir() {
      this.excluirUsuarioIds = this.usuariosAsignados.map(
        (ua) => ua.usuario_id
      );
    },
  },
};
