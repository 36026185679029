import RepoUtils from "./Request/RepoUtils";

export default {
  // GET
  listarGestor: (filtros) =>
    RepoUtils.ejecutarPeticionGet(filtros, "leads", "listarGestor"),
  listarDetalle: (filtros) =>
    RepoUtils.ejecutarPeticionGet(filtros, "leads", "listarDetalle"),
  obtenerDetalle: (filtros) =>
    RepoUtils.ejecutarPeticionGet(filtros, "leads", "obtenerDetalle"),
  obtenerConcentradoOpciones: (filtros) =>
    RepoUtils.ejecutarPeticionGet(
      filtros,
      "leads",
      "obtenerConcentradoOpciones"
    ),
  obtenerDetalleConversacion: (filtros) =>
    RepoUtils.ejecutarPeticionGet(filtros, "leads", "detalle-conversacion"),
  // POST
  agregarLead: (data) =>
    RepoUtils.ejecutarPeticionPost(data, "leads", "agregar"),
  editarLead: (data) => RepoUtils.ejecutarPeticionPost(data, "leads", "editar"),
  editarCanalCaptacion: (data) =>
    RepoUtils.ejecutarPeticionPost(data, "leads", "editarCanalCaptacion"),
  actualizarStatusLead: (data) =>
    RepoUtils.ejecutarPeticionPost(data, "leads", "actualizarStatus"),
  agregarTelefono: (data) =>
    RepoUtils.ejecutarPeticionPost(data, "leads", "agregarTelefono"),
  editarTelefono: (data) =>
    RepoUtils.ejecutarPeticionPost(data, "leads", "editarTelefono"),
  eliminarTelefono: (data) =>
    RepoUtils.ejecutarPeticionPost(data, "leads", "eliminarTelefono"),
  agregarEtiqueta: (data) =>
    RepoUtils.ejecutarPeticionPost(data, "leads", "agregarEtiqueta"),
  eliminarEtiqueta: (data) =>
    RepoUtils.ejecutarPeticionPost(data, "leads", "eliminarEtiqueta"),
  editarAgente: (data) =>
    RepoUtils.ejecutarPeticionPost(data, "leads", "editarAgente"),
  editarDatosCompania: (data) =>
    RepoUtils.ejecutarPeticionPost(data, "leads", "editarDatosCompania"),

  // CAMPANIAS
  listarCampanias: (filtros) =>
    RepoUtils.ejecutarPeticionGet(filtros, "campanias", "listadoBasico"),
  // USUARIOS
  listarUsuariosBasico: (filtros) =>
    RepoUtils.ejecutarPeticionGet(filtros, "usuarios", "listadoBasico"),
  // INDUSTRIAS
  listarIndustrias: (filtros) =>
    RepoUtils.ejecutarPeticionGet(filtros, "industrias", "listadoBasico"),
};
