
import OportunidadService from "@/Services/OportunidadService";
export default {
  name: "card-email-detalle",
  components: {
    CardArchivoEmailGlobal: () =>
      import("@/components/cards/CardArchivoEmailGlobal.vue"),
  },
  props: {
    emailObj: { type: Object, required: true },
    mostrarAccesosDirectos: { type: Boolean, default: false },
    mostrarIconoDescargaArchivo: { type: Boolean, default: false },
  },
  methods: {
    async descargarArchivo(oportunidadArchivo) {
      this.loader(true);
      let nombreArchivo = oportunidadArchivo.nombre_sistema;
      let extension = oportunidadArchivo.extension;
      let tipo = oportunidadArchivo.tipo;
      let registroFecha = oportunidadArchivo.registro_fecha;
      let data = {
        oportunidadArchivoId: oportunidadArchivo.oportunidad_archivo_id,
        nombreArchivo,
        extension,
        tipo,
        registroFecha,
      };
      await OportunidadService.descargarArchivo(data)
        .then(async (resp) => {
          let linkDatos = `data:application/${extension};base64,${resp.datos.base64}`;
          let linkDescarga = document.createElement("a");
          linkDescarga.href = linkDatos;
          linkDescarga.download = nombreArchivo;
          linkDescarga.click();
          this.mostrarAlerta(resp.mensaje, "exito");
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          this.loader(false);
        });
    },
  },
};
