
export default {
  name: "AgenteLista",
  props: {
    usuarios: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    actualizarAgente(usuario) {
      this.$emit("actualizar-agente", usuario);
    },
  },
};
