
import LeadService from "@/Services/LeadService";
import EtiquetaChip from "./EtiquetaChip.vue";
export default {
  name: "area-etiquetas",
  components: { EtiquetaChip },
  props: {
    contactoId: { type: [String, Number], required: true },
    etiquetasOpciones: { type: Array, required: true },
    etiquetasContacto: { type: Array, required: true },
    activarLoader: { type: Boolean, default: true },
    inputDeshabilitado: { type: Boolean, default: true },
    mostrarIconoEliminar: { type: Boolean, default: false },
  },
  data() {
    return {
      inputEtiquetas: "",
      etiquetas: this.etiquetasOpciones,
    };
  },
  methods: {
    async agregarEtiqueta(titulo) {
      if (this.activarLoader) this.loader(true);
      else this.$emit("manejar-loader", true);
      let datos = {
        titulo: titulo,
        leadId: this.contactoId,
      };
      await LeadService.agregarEtiqueta(datos)
        .then(() => {
          if (this.activarLoader) this.loader(false);
          this.$emit("etiqueta-agregada");
        })
        .catch((error) => {
          if (this.activarLoader) this.loader(false);
          else this.$emit("manejar-loader", false);
          this.mostrarAlerta(error, "error");
        });
    },
    async eliminarEtiqueta(etiqueta) {
      if (this.activarLoader) this.loader(true);
      else this.$emit("manejar-loader", true);
      let datos = {
        etiquetaId: etiqueta.etiqueta_id,
        leadEtiquetaId: etiqueta.lead_etiqueta_id,
      };
      await LeadService.eliminarEtiqueta(datos)
        .then(() => {
          if (this.activarLoader) this.loader(false);
          this.$emit("etiqueta-eliminada");
        })
        .catch((error) => {
          if (this.activarLoader) this.loader(false);
          else this.$emit("manejar-loader", false);
          this.mostrarAlerta(error, "error");
        });
    },
    async validarAgregarEtiqueta(tipo, etiqueta, doneFn = "") {
      let titulo = tipo == 1 ? etiqueta.titulo : etiqueta;
      if (_.isEmpty(titulo) || titulo.length < 2) return;
      let existente = this.etiquetasContacto.filter(
        (e) => e.titulo.toLowerCase() == titulo.toLowerCase()
      );
      if (_.isEmpty(existente)) {
        await this.agregarEtiqueta(titulo, false);
      }

      // Se limpian variables
      if (tipo == 2) doneFn(titulo);
      else this.inputEtiquetas = "";
    },
    filterFn(val, update, abort) {
      if (val.length < 2) {
        abort();
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        this.etiquetas = this.etiquetasOpciones.filter(
          (v) => v.titulo.toLowerCase().indexOf(needle) > -1
        );
      });
    },
  },
};
