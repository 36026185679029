
import LeadService from "@/Services/LeadService";
import PaisService from "@/Services/PaisService";
import EmailService from "@/Services/EmailService";
import MenuTelefonoLada from "./components/MenuTelefonoLada.vue";
import AgenteCard from "./components/agentes/AgenteCard.vue";
import CardEmailDetalle from "../emails/componentes/CardEmailDetalle.vue";
import AreaEtiquetas from "./components/AreaEtiquetas.vue";

export default {
  name: "LeadDetalle",
  components: {
    MenuTelefonoLada,
    AgenteCard,
    CardEmailDetalle,
    AreaEtiquetas,
  },
  data() {
    return {
      breadcrumbs: [
        { etiqueta: "Contactos", to: { name: "leadsGestor" } },
        { etiqueta: "Detalle" },
      ],
      leadId: this.$route.params.id,
      columnasOportunidades: [
        {
          label: "#Op",
          name: "folio",
          align: "center",
          headerStyle: "width: 6%",
        },
        {
          label: "Artículo",
          field: "articulo_nombre",
          align: "left",
          headerStyle: "width: 15%",
        },
        {
          label: "Descripción",
          field: "descripcion",
          align: "left",
          headerStyle: "width: 15%",
        },
        {
          label: "Agente asignado",
          field: "usuario_asignado",
          align: "left",
          headerStyle: "width: 15%",
        },
        {
          label: "Registro",
          field: "registro_fecha",
          align: "center",
          headerStyle: "width: 10%",
          format: (val) => this.formatearFecha(val, 2),
        },
        {
          label: "Fecha cierre",
          field: "fecha_cierre",
          align: "center",
          headerStyle: "width: 10%",
          format: (val) => this.formatearFecha(val, 2),
        },
        {
          label: "Valor de venta",
          field: "valor_venta",
          align: "right",
          headerStyle: "width: 10%",
          format: (val) => this.accounting.formatMoney(val),
        },
        {
          label: "Status",
          name: "status",
          align: "left",
          headerStyle: "width: 12%",
        },
      ],
      columnasEmailEnviados: [
        {
          label: "#Email",
          field: "folio_email",
          align: "center",
          headerStyle: "width: 7%",
        },
        {
          label: "#Op",
          name: "oportunidadFolio",
          field: "oportunidad_folio",
          align: "center",
          headerStyle: "width: 7%",
        },
        {
          label: "Asunto",
          field: "asunto",
          align: "left",
          headerStyle: "width: 40%",
        },
        {
          label: "Status",
          align: "left",
          name: "status",
          headerStyle: "width: 20%",
        },
        {
          label: "Registro",
          field: "registro_fecha",
          align: "left",
          headerStyle: "width: 20%",
          format: (val) => this.formatearFecha(val, 2, true),
        },
        {
          name: "opc",
          label: "",
          align: "left",
          headerStyle: "width: 6%",
        },
      ],
      seguimientos: [],
      columnasSeguimientos: [
        {
          label: "#Seg",
          field: "folio_seguimiento",
          align: "center",
          headerStyle: "width: 7%",
        },
        {
          label: "#Op",
          name: "oportunidadFolio",
          field: "oportunidad_folio",
          align: "center",
          headerStyle: "width: 7%",
        },
        {
          label: "Tipo seguimiento",
          field: "tipo_seguimiento_nombre",
          align: "left",
          headerStyle: "width: 10%",
        },
        {
          label: "Comentario",
          field: "comentario",
          align: "left",
          headerStyle: "width: 26%",
        },
        {
          label: "Registro",
          field: "registro_fecha",
          align: "left",
          headerStyle: "width: 12%",
          format: (val) => this.formatearFecha(val, 2, true),
        },
        {
          label: "Agente asignado",
          field: "registro_autor",
          align: "left",
          headerStyle: "width: 12%",
        },
      ],
      menuStatusOpciones: [
        {
          status: this.constantesStatusCadena.lead,
          claseBackground: "actualizar-status-lead-boton-bg-lead",
        },
        {
          status: this.constantesStatusCadena.cliente,
          claseBackground: "actualizar-status-lead-boton-bg-cliente",
        },
        {
          status: this.constantesStatusCadena.descartado,
          claseBackground: "actualizar-status-lead-boton-bg-descartado",
        },
      ],
      opcionesToolbar: [],
      opcionesToolbarCaptacion: [],
      opcionesToolbarCompania: [],
      ladaDefault: "",
      ladaIconoDefault: "",
      lead: {},
      editarLeadObj: {},
      oportunidades: [],
      emailEnviados: [],
      modalEditar: false,
      telefonosLeadOriginal: [],
      modalEliminarTelefono: false,
      eliminarTelefonoObj: {},
      etiquetasOpciones: [],
      // etiquetas: [],
      etiquetasLead: [],
      estadoOpciones: [],
      paisOpciones: [],
      modalEditarCanalCaptacion: false,
      modalEditarCompania: false,
      canalCaptacionOpciones: [],
      plataformaOpciones: [],
      campaniaOpciones: [],
      industriaOpciones: [],
      modalAgregarEditarTelefono: false,
      agregarTelefonoObj: {
        leadTelefonoId: "",
        lada: "",
        ladaIcono: "",
        numero: "",
        default: false,
      },
      ladas: this.constantesLadas.Ladas,
      telefonoDuplicadoObj: {},
      modalAdvertenciaTelefonoDuplicado: false,
      modalAgregaEditarTelTitulo: "",
      modalAgregaEditarTelBtnAceptar: "",
      telefonoTipoAccion: "",
      usuariosAsignados: [],
      propAgenteActualizarDatos: false,
      emailObj: {},
      modalVerEmail: false,
    };
  },
  mounted() {
    this.cargaInicial();
  },
  computed: {
    crearNombreCompleto() {
      return (
        this.editarLeadObj.nombre + " " + (this.editarLeadObj.apellido || "")
      );
    },
    oportunidadesValorVentaTotal() {
      return this.accounting.formatMoney(
        _.sumBy(this.oportunidades, (op) => Number(op.valor_venta || 0))
      );
    },
  },
  methods: {
    async cargaInicial() {
      this.loader(true);
      await this.obtenerDetalle(false);
      this.propAgenteActualizarDatos = !this.propAgenteActualizarDatos;
      this.loader(false);
    },
    llenarOpcionesToolbar() {
      this.opcionesToolbar = [
        {
          texto: "Editar datos generales",
          icono: "editar",
          accion: "abrirModalEditar",
          disabled:
            this.lead.status != 200 ||
            !this.permisos.existePermiso(this.permisos.CONTACTOS_EDITAR),
        },
      ];
      this.opcionesToolbarCaptacion = [
        {
          texto: "Editar datos de captación",
          icono: "editar",
          accion: "abrirModalEditarCanalCaptacion",
          disabled:
            this.lead.status != 200 ||
            !this.permisos.existePermiso(
              this.permisos.CONTACTOS_CANALCAPTACION_EDITAR
            ),
        },
      ];
      this.opcionesToolbarCompania = [
        {
          texto: "Editar datos compañia",
          icono: "editar",
          accion: "abrirModalEditarCompania",
          disabled:
            this.lead.status != 200 ||
            !this.permisos.existePermiso(
              this.permisos.CONTACTOS_COMPANIA_EDITAR
            ),
        },
      ];
    },
    validarCampoCompaniaRFC(val) {
      if (_.isEmpty(val)) return true;
      return val.length == 12 || "RFC inválido";
    },
    validarCampoCompaniaIngresosAnuales(val) {
      if (_.isEmpty(val)) return true;
      if (val < 1) return "Cantidad inválida";
      return this.validarDecimales(val);
    },
    // METODOS DE PETICIONES
    async obtenerDetalle(loader = true) {
      if (loader) this.loader(true);
      let datos = {
        leadId: this.leadId,
      };
      await LeadService.obtenerDetalle(datos)
        .then((resp) => {
          this.lead = resp.info;
          this.telefonosLeadOriginal = resp.telefonos;
          this.oportunidades = resp.oportunidades;
          this.emailEnviados = resp.emailEnviados;
          this.seguimientos = resp.seguimientos;
          this.etiquetasOpciones = resp.etiquetas;
          // this.etiquetas = resp.etiquetas;
          this.etiquetasLead = resp.etiquetasLead;
          this.canalCaptacionOpciones = resp.canalesCaptacion;
          this.plataformaOpciones = resp.plataformas;
          this.usuariosAsignados = resp.usuariosAsignados;

          if (this.lead.plataforma_id != null) {
            this.obtenerCampanias(this.lead.plataforma_id, false);
          }

          this.llenarOpcionesToolbar();

          // Se configura lada default
          let paisRegionCodigoDefault =
            localStorage.getItem("paisRegionCodigo");
          if (paisRegionCodigoDefault != null) {
            let lada = this.ladas.find(
              (l) => l.alpha3Code == paisRegionCodigoDefault
            );
            this.ladaDefault = "+" + lada.callingCodes[0];
            this.ladaIconoDefault = lada.flag;
          }

          if (loader) this.loader(false);
        })
        .catch((error) => {
          if (loader) this.loader(false);
          this.mostrarAlerta(error, "error");
        });
    },
    async obtenerPaisesEstados(
      listado = "listarPaises",
      paisId = "",
      limpiar = true,
      loader = false
    ) {
      let filtros = {};
      if (listado == "listarEstados") {
        if (limpiar) this.editarLeadObj.estado_id = "";
        filtros.paisId = paisId;
      }
      if (loader) this.loader(true);
      await PaisService[listado](filtros)
        .then((resp) => {
          if (listado == "listarPaises") this.paisOpciones = resp;
          else this.estadoOpciones = resp;
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          if (loader) this.loader(false);
        });
    },
    async obtenerCampanias(plataformaId, loader = true) {
      if (loader) this.loader(true);
      this.editarLeadObj.campania_id = null;
      let filtros = {
        plataformaId: plataformaId,
        ordenar: "titulo_asc",
        status: ["Activa"],
      };
      await LeadService.listarCampanias(filtros)
        .then((resp) => {
          this.campaniaOpciones = resp;
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          if (loader) this.loader(false);
        });
    },
    async obtenerIndustrias(loader = true) {
      if (loader) this.loader(true);
      let filtros = {
        clienteId: this.lead.cliente_id,
        status: [200],
        ordenar: "nombre_asc",
      };
      await LeadService.listarIndustrias(filtros)
        .then((resp) => {
          this.industriaOpciones = resp;
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          if (loader) this.loader(false);
        });
    },
    async obtenerDetalleEmail(email) {
      this.loader(true);
      let datos = {
        emailId: email.mensaje_email_id,
      };
      await EmailService.obtenerDetalle(datos)
        .then((resp) => {
          this.emailObj = email;
          this.emailObj.archivos = resp.archivos;
          this.loader(false);
          this.modalVerEmail = true;
        })
        .catch((error) => {
          this.loader(false);
          this.mostrarAlerta(error, "error");
        });
    },
    async editarLead(loader = true) {
      if (loader) this.loader(true);
      let lead = {
        leadId: this.editarLeadObj.lead_id,
        nombre: this.editarLeadObj.nombre,
        apellidos: this.editarLeadObj.apellido,
        email: this.editarLeadObj.email || "",
        direccion1: this.editarLeadObj.direccion1 || "",
        direccion2: this.editarLeadObj.direccion2 || "",
        codigoPostal: this.editarLeadObj.codigo_postal || "",
        ciudad: this.editarLeadObj.ciudad || "",
        paisId: this.editarLeadObj.pais_id || "",
        estadoId: this.editarLeadObj.estado_id || "",
      };
      await LeadService.editarLead(lead)
        .then(async (resp) => {
          this.cerrarModalEditar();
          await this.obtenerDetalle(false);
          this.mostrarAlerta(resp.mensaje, "exito");
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          if (loader) this.loader(false);
        });
    },
    async agregarTelefono() {
      this.loader(true);
      if (!this.lead.telefono) this.agregarTelefonoObj.default = true;
      let datos = {
        leadId: this.leadId,
        telefonos: JSON.stringify([this.agregarTelefonoObj]),
      };
      await LeadService.agregarTelefono(datos)
        .then(async (resp) => {
          if (resp.datos.status == 300) {
            this.telefonoDuplicadoObj = resp.datos.lead;
            this.modalAdvertenciaTelefonoDuplicado = true;
          } else {
            this.cerrarModalAgregarEditarTelefono();
            await this.obtenerDetalle(false);
            this.mostrarAlerta(resp.mensaje, "exito");
          }
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          this.loader(false);
        });
    },
    async editarTelefono() {
      this.loader(true);
      this.agregarTelefonoObj.numero =
        this.agregarTelefonoObj.numero.replaceAll("-", "");
      let datos = {
        leadId: this.leadId,
        telefono: JSON.stringify(this.agregarTelefonoObj),
      };
      await LeadService.editarTelefono(datos)
        .then(async (resp) => {
          if (resp.datos.status == 300) {
            this.telefonoDuplicadoObj = resp.datos.lead;
            this.modalAdvertenciaTelefonoDuplicado = true;
          } else {
            this.cerrarModalAgregarEditarTelefono();
            await this.obtenerDetalle(false);
            this.mostrarAlerta(resp.mensaje, "exito");
          }
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          this.loader(false);
        });
    },
    eliminarTelefono() {
      this.loader(true);
      let datos = {
        leadTelefonoId: this.eliminarTelefonoObj.lead_telefono_id,
      };
      LeadService.eliminarTelefono(datos)
        .then((resp) => {
          this.cerrarModalEliminarTelefono();
          this.obtenerDetalle(false);
          this.mostrarAlerta(resp.mensaje, "exito");
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => this.loader(false));
    },
    async agregarEtiqueta(titulo, loader = true) {
      if (loader) this.loader(true);
      let datos = {
        titulo: titulo,
        leadId: this.leadId,
      };
      await LeadService.agregarEtiqueta(datos)
        .then(() => {
          this.obtenerDetalle();
        })
        .catch((error) => {
          this.loader(false);
          this.mostrarAlerta(error, "error");
        });
    },
    async eliminarEtiqueta(etiqueta, loader = true) {
      if (loader) this.loader(true);
      let datos = {
        etiquetaId: etiqueta.etiqueta_id,
        leadEtiquetaId: etiqueta.lead_etiqueta_id,
      };
      await LeadService.eliminarEtiqueta(datos)
        .then(() => {
          this.obtenerDetalle(true);
        })
        .catch((error) => {
          if (loader) this.loader(false);
          this.mostrarAlerta(error, "error");
        });
    },
    async editarCanalCaptacion(loader = true) {
      if (loader) this.loader(true);
      let datos = {
        leadId: this.editarLeadObj.lead_id,
        canalCaptacionId: this.editarLeadObj.canal_captacion_id,
        plataformaId: this.editarLeadObj.plataforma_id,
        campaniaId: this.editarLeadObj.campania_id,
      };
      await LeadService.editarCanalCaptacion(datos)
        .then((resp) => {
          this.cerrarModalEditarCanalCaptacion();
          this.obtenerDetalle(false);
          this.mostrarAlerta(resp.mensaje, "exito");
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          if (loader) this.loader(false);
        });
    },
    async actualizarStatusLead(statusNuevo) {
      if (this.lead.status_lead == statusNuevo) return;

      this.loader(true);
      let datos = {
        leadId: this.leadId,
        statusNuevo: statusNuevo,
      };
      await LeadService.actualizarStatusLead(datos)
        .then(async (resp) => {
          await this.obtenerDetalle(false);
          this.mostrarAlerta(resp.mensaje, "exito");
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          this.loader(false);
        });
    },
    async editarDatosCompania(loader = true) {
      if (loader) this.loader(true);
      let datos = {
        leadId: this.editarLeadObj.lead_id,
        nombreComercial: this.editarLeadObj.nombre_comercial || "",
        rfc: this.editarLeadObj.rfc || "",
        sitioWeb: this.editarLeadObj.sitio_web || "",
        industriaId: this.editarLeadObj.industria_id || "",
        numeroColaboradores: this.editarLeadObj.numero_colaboradores || "",
        ingresosAnuales: this.editarLeadObj.ingresos_anuales || "",
      };
      await LeadService.editarDatosCompania(datos)
        .then(async (resp) => {
          this.cerrarModalEditarCompania();
          await this.obtenerDetalle(false);
          this.mostrarAlerta(resp.mensaje, "exito");
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          if (loader) this.loader(false);
        });
    },

    // METODOS PARA MANEJAR DATOS/ACCIONES
    async abrirModalEditar() {
      this.loader(true);
      // Se listan paises si aun no se han listado
      if (_.isEmpty(this.paisOpciones)) await this.obtenerPaisesEstados();

      // Se listan estados si el contacto tiene pais
      if (this.lead.pais_id != null)
        await this.obtenerPaisesEstados(
          "listarEstados",
          this.lead.pais_id,
          false
        );
      this.loader(false);
      this.editarLeadObj = _.cloneDeep(this.lead);
      this.modalEditar = true;
    },
    cerrarModalEditar() {
      this.editarLeadObj = {};
      this.modalEditar = false;
    },
    abrirModalEditarCanalCaptacion() {
      this.editarLeadObj = _.cloneDeep(this.lead);
      this.modalEditarCanalCaptacion = true;
    },
    cerrarModalEditarCanalCaptacion() {
      this.editarLeadObj = {};
      this.modalEditarCanalCaptacion = false;
    },
    async abrirModalEditarCompania() {
      if (_.isEmpty(this.industriaOpciones)) await this.obtenerIndustrias();
      this.editarLeadObj = _.cloneDeep(this.lead);
      this.modalEditarCompania = true;
    },
    cerrarModalEditarCompania() {
      this.editarLeadObj = {};
      this.modalEditarCompania = false;
    },
    async abrirModalAgregarTelefono() {
      this.telefonoTipoAccion = 1;
      this.modalAgregaEditarTelTitulo = "Agregar teléfono";
      this.modalAgregaEditarTelBtnAceptar = "Agregar";
      this.agregarTelefonoObj.lada = this.ladaDefault;
      this.agregarTelefonoObj.ladaIcono = this.ladaIconoDefault;
      this.modalAgregarEditarTelefono = true;
    },
    cerrarModalAgregarEditarTelefono() {
      this.modalAgregarEditarTelefono = false;
      this.agregarTelefonoObj = {
        lada: "",
        ladaIcono: "",
        numero: "",
        default: false,
      };
      this.modalAgregaEditarTelTitulo = "";
      this.modalAgregaEditarTelBtnAceptar = "";
      this.telefonoTipoAccion = "";
    },
    agregarEditarTelefono() {
      if (this.telefonoTipoAccion == 1) this.agregarTelefono();
      else this.editarTelefono();
    },
    async abrirModalEditarTelefono(telefono, telefonoDefault = false) {
      this.telefonoTipoAccion = 2;
      this.agregarTelefonoObj.leadTelefonoId = telefono.lead_telefono_id;
      this.agregarTelefonoObj.lada = telefono.telefono_lada;
      this.agregarTelefonoObj.numero = telefono.telefono_sin_lada;
      this.agregarTelefonoObj.ladaIcono = this.ladas.find((l) =>
        l.callingCodes.includes(telefono.telefono_lada.substring(1))
      ).flag;
      this.agregarTelefonoObj.default = telefonoDefault;
      this.modalAgregaEditarTelTitulo = "Editar teléfono";
      this.modalAgregaEditarTelBtnAceptar = "Guardar Cambios";
      this.modalAgregarEditarTelefono = true;
    },
    cerrarModalAdvertenciaTelefonoDuplicado() {
      this.telefonoDuplicadoObj = {};
      this.modalAdvertenciaTelefonoDuplicado = false;
    },
    abrirModalEliminarTelefono(telefono) {
      this.eliminarTelefonoObj = telefono;
      this.modalEliminarTelefono = true;
    },
    cerrarModalEliminarTelefono() {
      this.eliminarTelefonoObj = {};
      this.modalEliminarTelefono = false;
    },
    seleccionarLada(lada) {
      this.agregarTelefonoObj.ladaIcono = lada.flag;
      this.agregarTelefonoObj.lada = "+" + lada.callingCodes[0];
    },
    cerrarModalVerEmail() {
      this.emailObj = {};
      this.modalVerEmail = false;
    },
    async manejarEtiquetaEliminada() {
      await this.obtenerDetalle(true);
    },
    async manejarEtiquetaAgregada() {
      await this.obtenerDetalle(true);
    },
  },
};
