import RepoUtils from "./Request/RepoUtils";

export default {
  // GET
  listarPaises: (filtros) =>
    RepoUtils.ejecutarPeticionGet(filtros, "paises", "listarPaises"),
  listarEstados: (filtros) =>
    RepoUtils.ejecutarPeticionGet(filtros, "paises", "listarEstados"),
  listarLadas: () =>
    RepoUtils.ejecutarPeticionGetExterna(
      { fields: "name,nativeName,flag,callingCodes,translations" },
      process.env.VUE_APP_REST_COUNTRIES_URL,
      "region",
      "Americas"
    ),
};
