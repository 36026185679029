
import LeadService from "@/Services/LeadService";
import AgenteLista from "./AgenteLista.vue";
export default {
  name: "AgenteSeccion",
  props: {
    subtitulo: {
      type: String,
      default: "",
    },
    esPrimario: {
      type: Boolean,
      default: true,
    },
    agente: {
      type: Object,
      default: () => {},
    },
    actualizarDatos: {
      type: Boolean,
      default: false,
    },
    lead: {
      type: Object,
      default: () => {},
    },
    usuarios: {
      type: Array,
      default: () => [],
    },
    mostrarListaAgentes: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    AgenteLista,
  },
  data() {
    return {
      agenteNombre: "",
    };
  },
  watch: {
    async actualizarDatos() {
      this.agenteNombre = this.agente.nombre_corto;
    },
  },
  methods: {
    async actualizarAgente(usuario) {
      this.loader(true);
      let data = {
        leadId: this.lead.lead_id,
        esPrimario: this.esPrimario ? "si" : "no",
        usuarioId: usuario.usuario_id,
      };
      await LeadService.editarAgente(data)
        .then((resp) => {
          this.mostrarAlerta(resp.mensaje, "exito");
          this.$emit("recargar");
        })
        .catch((error) => {
          this.mostrarAlerta(error, "error");
          this.loader(false);
        });
    },
  },
};
